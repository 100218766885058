// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import ProjectReporting from "../../blocks/ProjectReporting/src/ProjectReporting";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import LoyaltySystem2 from "../../blocks/LoyaltySystem2/src/LoyaltySystem2";
import TaskViews from "../../blocks/TaskViews/src/TaskViews";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import BarcodesScanner from "../../blocks/BarcodesScanner/src/BarcodesScanner";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import OrganisationHierarchy from "../../blocks/OrganisationHierarchy/src/OrganisationHierarchy";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import CfThermopatchTaggingSolutionIntegration from "../../blocks/CfThermopatchTaggingSolutionIntegration/src/CfThermopatchTaggingSolutionIntegration";
import HelpdeskReporting from "../../blocks/HelpdeskReporting/src/HelpdeskReporting";
import TaxCalculator from "../../blocks/TaxCalculator/src/TaxCalculator";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import ExpressDelivery from "../../blocks/ExpressDelivery/src/ExpressDelivery";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CfWhatsappApi from "../../blocks/CfWhatsappApi/src/CfWhatsappApi";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import OrderCreation from "../../blocks/OrderCreation/src/OrderCreation";
import Payments from "../../blocks/Payments/src/Payments";
import CfMetalProgettiSolutionIntegration2 from "../../blocks/CfMetalProgettiSolutionIntegration2/src/CfMetalProgettiSolutionIntegration2";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import CfStoreManagment from "../../blocks/CfStoreManagment/src/CfStoreManagment";
import AgentDashboard2 from "../../blocks/AgentDashboard2/src/AgentDashboard2";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import AccountTransfer from "../../blocks/AccountTransfer/src/AccountTransfer";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import Notes from "../../blocks/Notes/src/Notes";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Geofence from "../../blocks/geofence/src/Geofence";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import CfSubCompany from "../../blocks/CfSubCompany/src/CfSubCompany";
import TicketSystem from "../../blocks/TicketSystem/src/TicketSystem";
import OfflineWork from "../../blocks/OfflineWork/src/OfflineWork";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import Crm3rdPartyIntegration from "../../blocks/Crm3rdPartyIntegration/src/Crm3rdPartyIntegration";
import CfPriceList from "../../blocks/CfPriceList/src/CfPriceList";
import Analytics from "../../blocks/analytics/src/Analytics";
import AmazonPayIntegration from "../../blocks/AmazonPayIntegration/src/AmazonPayIntegration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import ItemAvailability from "../../blocks/ItemAvailability/src/ItemAvailability";
import RefundManagement from "../../blocks/RefundManagement/src/RefundManagement";
import FormApprovalWorkflow from "../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserGroups2 from "../../blocks/UserGroups2/src/UserGroups2";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import InventoryManagement2 from "../../blocks/InventoryManagement2/src/InventoryManagement2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import BarcodeSettings from "../../blocks/BarcodeSettings/src/BarcodeSettings";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import ItemGrouper from "../../blocks/ItemGrouper/src/ItemGrouper";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import ReceiptCustomisation from "../../blocks/ReceiptCustomisation/src/ReceiptCustomisation";
import CfCashier from "../../blocks/CfCashier/src/CfCashier";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CfRegionsManagement from "../../blocks/CfRegionsManagement/src/CfRegionsManagement";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Sorting from "../../blocks/sorting/src/Sorting";
import Location from "../../blocks/location/src/Location";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Surveys from "../../blocks/Surveys/src/Surveys";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import LanguageOptions from "../../blocks/LanguageOptions/src/LanguageOptions";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import ApplePayIntegration from "../../blocks/ApplePayIntegration/src/ApplePayIntegration";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import PerformanceTracker from "../../blocks/PerformanceTracker/src/PerformanceTracker";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import Archive from "../../blocks/Archive/src/Archive";
import CreateComment from "../../blocks/comments/src/CreateComment";
import TaskList from "../../blocks/TaskList/src/TaskList";
import StoreLocator from "../../blocks/StoreLocator/src/StoreLocator";
import SalesReporting from "../../blocks/SalesReporting/src/SalesReporting";



const routeMap = {
ProjectReporting:{
 component:ProjectReporting,
path:"/ProjectReporting"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
TaskAllocator:{
 component:TaskAllocator,
path:"/TaskAllocator"},
LoyaltySystem2:{
 component:LoyaltySystem2,
path:"/LoyaltySystem2"},
TaskViews:{
 component:TaskViews,
path:"/TaskViews"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
BarcodesScanner:{
 component:BarcodesScanner,
path:"/BarcodesScanner"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
MultipleCurrencySupport:{
 component:MultipleCurrencySupport,
path:"/MultipleCurrencySupport"},
OrganisationHierarchy:{
 component:OrganisationHierarchy,
path:"/OrganisationHierarchy"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
CfThermopatchTaggingSolutionIntegration:{
 component:CfThermopatchTaggingSolutionIntegration,
path:"/CfThermopatchTaggingSolutionIntegration"},
HelpdeskReporting:{
 component:HelpdeskReporting,
path:"/HelpdeskReporting"},
TaxCalculator:{
 component:TaxCalculator,
path:"/TaxCalculator"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
ExpressDelivery:{
 component:ExpressDelivery,
path:"/ExpressDelivery"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
CfWhatsappApi:{
 component:CfWhatsappApi,
path:"/CfWhatsappApi"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
OrderCreation:{
 component:OrderCreation,
path:"/OrderCreation"},
Payments:{
 component:Payments,
path:"/Payments"},
CfMetalProgettiSolutionIntegration2:{
 component:CfMetalProgettiSolutionIntegration2,
path:"/CfMetalProgettiSolutionIntegration2"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
CfStoreManagment:{
 component:CfStoreManagment,
path:"/CfStoreManagment"},
AgentDashboard2:{
 component:AgentDashboard2,
path:"/AgentDashboard2"},
QrCodes:{
 component:QrCodes,
path:"/QrCodes"},
AccountTransfer:{
 component:AccountTransfer,
path:"/AccountTransfer"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
Notes:{
 component:Notes,
path:"/Notes"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Customform:{
 component:Customform,
path:"/Customform"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
CfSubCompany:{
 component:CfSubCompany,
path:"/CfSubCompany"},
TicketSystem:{
 component:TicketSystem,
path:"/TicketSystem"},
OfflineWork:{
 component:OfflineWork,
path:"/OfflineWork"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ServiceSpecificSettingsAdmin:{
 component:ServiceSpecificSettingsAdmin,
path:"/ServiceSpecificSettingsAdmin"},
Crm3rdPartyIntegration:{
 component:Crm3rdPartyIntegration,
path:"/Crm3rdPartyIntegration"},
CfPriceList:{
 component:CfPriceList,
path:"/CfPriceList"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
AmazonPayIntegration:{
 component:AmazonPayIntegration,
path:"/AmazonPayIntegration"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
ItemAvailability:{
 component:ItemAvailability,
path:"/ItemAvailability"},
RefundManagement:{
 component:RefundManagement,
path:"/RefundManagement"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
MultilevelApproval:{
 component:MultilevelApproval,
path:"/MultilevelApproval"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
UserGroups2:{
 component:UserGroups2,
path:"/UserGroups2"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
InventoryManagement2:{
 component:InventoryManagement2,
path:"/InventoryManagement2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
BarcodeSettings:{
 component:BarcodeSettings,
path:"/BarcodeSettings"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
ItemGrouper:{
 component:ItemGrouper,
path:"/ItemGrouper"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
ReceiptCustomisation:{
 component:ReceiptCustomisation,
path:"/ReceiptCustomisation"},
CfCashier:{
 component:CfCashier,
path:"/CfCashier"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CfRegionsManagement:{
 component:CfRegionsManagement,
path:"/CfRegionsManagement"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Location:{
 component:Location,
path:"/Location"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Surveys:{
 component:Surveys,
path:"/Surveys"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
ApplePayIntegration:{
 component:ApplePayIntegration,
path:"/ApplePayIntegration"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
Archive:{
 component:Archive,
path:"/Archive"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
StoreLocator:{
 component:StoreLocator,
path:"/StoreLocator"},
SalesReporting:{
 component:SalesReporting,
path:"/SalesReporting"},

  Home: {
component:ShoppingCartOrders,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
